import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import '@/styles/index.scss'
import BaiduMap from 'vue-baidu-map'

Vue.use(BaiduMap, { ak: 'bOF9gGWXz76q065otbYtWN4A' })

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
