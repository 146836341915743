import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/home'
  },
  {
    path: '/home',
    name: 'home',
    component: () => import('../views/home')
  },
  {
    path: '/order',
    name: 'order',
    component: () => import('../views/order')
  },
  {
    path: '/marketing',
    name: 'marketing',
    component: () => import('../views/marketing')
  },
  {
    path: '/analyze',
    name: 'analyze',
    component: () => import('../views/analyze')
  },
  {
    path: '/culture',
    name: 'culture',
    component: () => import('../views/culture')
  },
  {
    path: '/contact-us',
    name: 'contactUs',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('../views/contact-us')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior: (to, from, savedPosition) => {
    if (savedPosition) {
      return savedPosition // 浏览器上的前进后退按钮记录的位置对象
    } else {
      return {
        y: 0
      }
    }
  },
  routes
})

export default router
